import React from "react";
import { useStaticQuery, graphql } from "gatsby";

export const OpenCodeEditor = ({ href }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(absolutePath: { regex: "/codesandbox.png/" }) {
        childImageSharp {
          fixed(width: 48, height: 48) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return (
    <div css={{ marginBottom: "10px" }}>
      <a
        href={href}
        target="_blank"
        rel="noreferrer noopener"
        css={{ display: "flex", alignItems: "center" }}
      >
        <img
          src={data.logo.childImageSharp.fixed.src}
          css={{ width: `40px`, marginRight: `5px` }}
          alt="TypeScript"
        />
        <span>Open exercise in CodeSandbox</span>
      </a>
    </div>
  );
};
