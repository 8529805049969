import React from "react";
import { Code } from "./code";

export const RevealCode = ({ language, code }) => {
  const [show, setShow] = React.useState(false);

  const handleClick = () => {
    setShow(!show);
  };
  return (
    <div css={{ marginBottom: "10px" }}>
      <div
        css={{
          padding: "answer-container",
        }}
      >
        <button
          onClick={handleClick}
          css={{
            margin: "8px auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px 12px",
            minWidth: "180px",
            fontWeight: "600",
            fontSize: "0.8rem",
            lineHeight: "24px",
            whiteSpace: "nowrap",
            borderRadius: "4px",
            border: "1px solid #d8d8d8",
            letterSpacing: "0.35px",
            backgroundColor: "#fbfbfb",
            cursor: "pointer",
            ":hover": {
              backgroundColor: "#f7f7f7",
            },
            ":focus": {
              outline: "none",
            },
          }}
        >
          <Bulb />
          <span>{show ? "Hide Code" : "Show Code"}</span>
        </button>
        {show && (
          <div
            css={{
              margin: "0",
              animation: "fadeIn ease 1s",
              WebkitAnimation: "fadeIn ease 1s",
              MozAnimation: "fadeIn ease 1s",
            }}
          >
            <Code codeString={code} language={language}></Code>
          </div>
        )}
      </div>
    </div>
  );
};

const Bulb = () => (
  <span css={{ marginRight: "5px" }} role="img" aria-label="Bulb">
    💡
  </span>
);
