import React from "react";
import Highlight, { defaultProps } from "prism-react-renderer";
import theme from "prism-react-renderer/themes/dracula";

const copyToClipboard = (str) => {
  const { clipboard } = window.navigator;
  /*
   * fallback to older browsers (including Safari)
   * if clipboard API not supported
   */
  if (!clipboard || typeof clipboard.writeText !== `function`) {
    const textarea = document.createElement(`textarea`);
    textarea.value = str;
    textarea.setAttribute(`readonly`, true);
    textarea.setAttribute(`contenteditable`, true);
    textarea.style.position = `absolute`;
    textarea.style.left = `-9999px`;
    document.body.appendChild(textarea);
    textarea.select();
    const range = document.createRange();
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
    textarea.setSelectionRange(0, textarea.value.length);
    document.execCommand(`copy`);
    document.body.removeChild(textarea);

    return Promise.resolve(true);
  }

  return clipboard.writeText(str);
};

export const Code = ({ codeString, language, suppressCopy }) => {
  const [copied, setCopied] = React.useState(false);
  const handleClick = () => {
    setCopied(true);
    copyToClipboard(codeString);

    setTimeout(() => {
      setCopied(false);
    }, 4000);
  };
  return (
    <div className="gatsby-highlight">
      <Highlight
        {...defaultProps}
        code={codeString.trim()}
        language={language}
        theme={theme}
      >
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <pre
            className={className}
            style={style}
            css={{
              textAlign: "left",
              padding: language ? "2rem 1rem 1rem 1rem" : "1rem",
              overflow: "auto",
              whiteSpace: "pre",
              wordSpacing: "normal",
              wordBreak: "normal",
              WebkitOverflowScrolling: "touch",
              overflowWrap: "break-word",
              boxShadow: "1px 1px 20px rgba(20, 20, 20, 0.27)",
              borderRadius: "5px",
              code: {
                float: "left",
                minWidth: "100%",
                paddingRight: "1rem",
              },
              ".token-line": {
                lineHeight: "22px",
                fontSize: "14px",
                minWidth: "100%",
              },
              ".highlight-line": {
                backgroundColor: "#44475a",
                marginLeft: "-1rem",
                marginRight: "-2rem",
                padding: "0 0.75rem",
                borderLeft: "4px solid #3b3b5d",
              },
            }}
          >
            {!suppressCopy && (
              <button
                onClick={handleClick}
                disabled={copied}
                css={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  zIndex: "1",
                  borderRadius: "0 5px 0 5px",
                  padding: "0.25rem 0.6rem",
                  border: "none",
                  cursor: "pointer",
                  background: "#44475a",
                  color: "rgb(248, 248, 242)",
                  transition: "all 200ms ease",
                  fontSize: "12px",
                }}
              >
                {copied ? "Copied!" : "Copy"}
              </button>
            )}
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </pre>
        )}
      </Highlight>
    </div>
  );
};
