import React from "react";
import ReactMarkdown from "react-markdown";
import { Code } from "./code";

const getInitialResponses = (asks) =>
  asks.map((ask) => (ask.type === "multi-correct" ? [] : undefined));

const getInitialMarks = (asks) => asks.map(() => undefined);

export const Quiz = ({ asks, pass, next }) => {
  const [responses, setResponses] = React.useState(getInitialResponses(asks));
  const [marks, setMarks] = React.useState(getInitialMarks(asks));

  const handleResponseChanged = (index) => (newResponse) => {
    responses[index] = newResponse;
    setResponses([...responses]);
  };

  const handleCheckAnswersClick = () => {
    setMarks(
      asks.map((ask, index) => {
        if (ask.type === "multi-correct") {
          if (ask.answers.length === 0) {
            return "unanswered";
          }
          let correctAnswers = [];
          ask.answers.forEach((answer, i) => {
            if (answer.correct) {
              correctAnswers.push(i);
            }
          });
          if (
            JSON.stringify(correctAnswers) ===
            JSON.stringify(responses[index].sort())
          ) {
            return "correct";
          }
          return "wrong";
        } else {
          return responses[index] === undefined
            ? "unanswered"
            : ask.answers[responses[index]].correct
            ? "correct"
            : "wrong";
        }
      })
    );
  };

  const isMarked = marks.filter((mark) => mark !== undefined).length > 0;
  let correctCount = 0;
  marks.forEach((mark) => {
    if (mark === "correct") {
      correctCount++;
    }
  });

  return (
    <div
      css={{
        borderTop: "#dcdcdc solid 1px",
        paddingTop: "20px",
        marginBottom: "30px",
      }}
    >
      {asks.map((ask, index) => (
        <Ask
          key={index}
          number={index + 1}
          type={ask.type}
          question={ask.question}
          questionCode={ask.questionCode}
          questionCodeLanguage={ask.questionCodeLanguage}
          question2={ask.question2}
          questionCode2={ask.questionCode2}
          questionCodeLanguage2={ask.questionCodeLanguage2}
          answers={ask.answers}
          response={responses[index]}
          onResponseChanged={handleResponseChanged(index)}
          mark={marks[index]}
          explanation={ask.explanation}
        />
      ))}
      <div
        css={{
          textAlign: "center",
        }}
      >
        <button
          css={{
            padding: "10px 12px",
            minWidth: "180px",
            fontWeight: "700",
            lineHeight: "24px",
            whiteSpace: "nowrap",
            borderRadius: "4px",
            border: "1px solid #d8d8d8",
            letterSpacing: "0.35px",
            backgroundColor: "#fbfbfb",
            cursor: "pointer",
            ":focus": {
              outline: "none",
            },
          }}
          onClick={handleCheckAnswersClick}
        >
          Check Answers
        </button>
      </div>
      <div>
        {isMarked && correctCount >= pass && (
          <p
            css={{
              margin: "1rem 0 -1rem",
              animation: "fadeIn ease 1s",
              WebkitAnimation: "fadeIn ease 1s",
              MozAnimation: "fadeIn ease 1s",
            }}
          >
            Well done!
          </p>
        )}
        {isMarked && correctCount < pass && (
          <p
            css={{
              margin: "1rem 0 -1rem",
              animation: "fadeIn ease 1s",
              WebkitAnimation: "fadeIn ease 1s",
              MozAnimation: "fadeIn ease 1s",
            }}
          >
            It might be worth going through some of the lessons again and
            retaking this quiz
          </p>
        )}
        {isMarked && correctCount >= pass && (
          <p
            css={{
              margin: "1.5rem 0",
              animation: "fadeIn ease 1s",
              WebkitAnimation: "fadeIn ease 1s",
              MozAnimation: "fadeIn ease 1s",
            }}
          >
            {next}
          </p>
        )}
      </div>
    </div>
  );
};

const Ask = ({
  number,
  type,
  question,
  questionCode,
  questionCodeLanguage = "typescript",
  question2,
  questionCode2,
  questionCodeLanguage2 = "typescript",
  answers,
  response,
  onResponseChanged,
  mark,
  explanation,
}) => {
  const handleChecked = (answerIndex) => () => {
    if (type === "multi-correct") {
      const posn = response.indexOf(answerIndex);
      if (posn === -1) {
        response.push(answerIndex);
      } else {
        response.splice(posn, 1);
      }
      onResponseChanged([...response]);
    } else {
      onResponseChanged(answerIndex);
    }
  };

  return (
    <div
      css={{
        paddingBottom: "20px",
        marginBottom: "30px",
        borderBottom: "#dcdcdc solid 1px",
      }}
      className={mark === undefined ? `quiz-ask` : `quiz-ask quiz-ask-${mark}`}
    >
      <div
        css={{
          display: "flex",
        }}
      >
        <span
          css={{
            marginRight: "5px",
            color: "#42424a",
            lineHeight: "28px",
          }}
        >
          {number}.
        </span>
        <div>
          <ReactMarkdown source={question} />
          {questionCode && (
            <div>
              <Code
                codeString={questionCode}
                language={questionCodeLanguage}
              ></Code>
            </div>
          )}
          {question2 && <ReactMarkdown source={question2} />}
          {questionCode2 && (
            <div>
              <Code
                codeString={questionCode2}
                language={questionCodeLanguage2}
              ></Code>
            </div>
          )}
        </div>
      </div>
      <div
        css={{
          display: "flex",
          alignItems: "center",
          marginLeft: "10px",
        }}
      >
        {mark && (
          <div
            css={{
              marginRight: "10px",
              animation: "fadeIn ease 1s",
              WebkitAnimation: "fadeIn ease 1s",
              MozAnimation: "fadeIn ease 1s",
            }}
          >
            {mark === "correct" ? <Correct /> : <Wrong />}
          </div>
        )}
        <div>
          {answers.map((answer, index) => (
            <Answer
              key={answer.text}
              type={type}
              answer={answer}
              checked={
                type === "multi-correct"
                  ? response.indexOf(index) > -1
                  : response === index
              }
              onChecked={handleChecked(index)}
            />
          ))}
        </div>
      </div>
      {mark && (
        <div
          css={{
            borderRadius: "5px",
            background: "#F2F2FA",
            color: "#42424a",
            margin: "1rem 0 0",
            padding: "1rem",
            animation: "fadeIn ease 1s",
            WebkitAnimation: "fadeIn ease 1s",
            MozAnimation: "fadeIn ease 1s",
            p: {
              marginBottom: "0",
            },
          }}
        >
          <ReactMarkdown source={explanation} />
        </div>
      )}
    </div>
  );
};

const Answer = ({ type = "single-correct", answer, checked, onChecked }) => {
  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
        marginLeft: "10px",
      }}
    >
      {type === "single-correct" && (
        <RadioButton
          label={answer.text}
          labelCode={answer.textCode}
          labelCodeLanguage={answer.textCodeLanguage}
          checked={checked}
          onChecked={onChecked}
        />
      )}
      {type === "multi-correct" && (
        <TickButton
          label={answer.text}
          labelCode={answer.textCode}
          labelCodeLanguage={answer.textCodeLanguage}
          checked={checked}
          onChecked={onChecked}
        />
      )}
    </div>
  );
};

const RadioButton = ({
  label,
  labelCode,
  labelCodeLanguage = "typescript",
  checked,
  onChecked,
}) => {
  const handleChecked = () => {
    onChecked();
  };
  return (
    <button
      css={{
        display: "flex",
        border: "none",
        backgroundColor: "transparent",
        padding: "0 0 8px 0",
        cursor: "pointer",
        ":focus": {
          outline: "none",
        },
      }}
      onClick={handleChecked}
    >
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="transparent"
          strokeWidth="1.2"
          css={{
            marginRight: "5px",
          }}
        >
          <circle
            cx="12"
            cy="12"
            r="10"
            fill="transparent"
            stroke="#2d2d2d"
          ></circle>
          {checked && (
            <circle
              id="circle"
              cx="12"
              cy="12"
              r="5"
              stroke="#3b3b5d"
              fill="#3b3b5d"
            ></circle>
          )}
        </svg>
      </div>
      <div
        css={{
          p: {
            margin: "0 0 0.5rem",
            textAlign: "left",
          },
          "pre[class*='language-']": {
            margin: "0 0 0.5rem",
          },
        }}
      >
        <ReactMarkdown source={label} />
        {labelCode && (
          <div>
            <Code codeString={labelCode} language={labelCodeLanguage}></Code>
          </div>
        )}
      </div>
    </button>
  );
};

const TickButton = ({
  label,
  labelCode,
  labelCodeLanguage = "typescript",
  checked,
  onChecked,
}) => {
  const handleChecked = () => {
    onChecked();
  };
  return (
    <button
      css={{
        display: "flex",
        border: "none",
        backgroundColor: "transparent",
        padding: "0",
        cursor: "pointer",
        ":focus": {
          outline: "none",
        },
        svg: {
          marginRight: "5px",
        },
      }}
      onClick={handleChecked}
    >
      <svg
        width="20px"
        height="20px"
        viewBox="0 0 20 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g transform="translate(-860 -1386)">
            <g transform="translate(861 1387)">
              <rect stroke="#2d2d2d" width="18" height="18" rx="4"></rect>
              {checked && (
                <polyline
                  stroke="#3b3b5d"
                  strokeWidth="2.5"
                  points="4 10.6 6.30769231 13 14 5"
                ></polyline>
              )}
            </g>
          </g>
        </g>
      </svg>
      <div
        css={{
          p: {
            margin: "0",
          },
        }}
      >
        <ReactMarkdown source={label} />
        {labelCode && (
          <div>
            <Code codeString={labelCode} language={labelCodeLanguage}></Code>
          </div>
        )}
      </div>
    </button>
  );
};

const Correct = () => (
  <svg width="40" height="40" viewBox="0 0 27 27" version="1.1">
    <g id="UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-433.000000, -233.000000)" stroke="#1ea69a">
        <g transform="translate(434.000000, 234.000000)">
          <circle
            strokeWidth="2"
            fill="white"
            cx="12.5"
            cy="12.5"
            r="12.5"
          ></circle>
          <polyline
            id="Path-28"
            strokeWidth="2.8"
            strokeLinecap="round"
            strokeLinejoin="round"
            points="8 12.4348143 11.8526579 16 17 8"
          ></polyline>
        </g>
      </g>
    </g>
  </svg>
);

const Wrong = () => (
  <svg width="40" height="40" viewBox="0 0 27 27">
    <g
      transform="translate(1 1)"
      stroke="#c34c40"
      fill="none"
      fillRule="evenodd"
    >
      <circle strokeWidth="2" fill="#fff" cx="12.5" cy="12.5" r="12.5"></circle>
      <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.8">
        <path d="M9 16l8-7M17 16L9 9"></path>
      </g>
    </g>
  </svg>
);
