import { Question } from "../../../../src/docs/question";
import { RevealCode } from "../../../../src/docs/reveal-code";
import { OpenCodeEditor } from "../../../../src/docs/opencodeeditor";
import { Tweet } from "../../../../src/docs/tweet";
import * as React from 'react';
export default {
  Question,
  RevealCode,
  OpenCodeEditor,
  Tweet,
  React
};