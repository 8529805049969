import React from "react";
import Helmet from "react-helmet";

export const Tweet = ({ title }) => {
  React.useEffect(() => {
    if (typeof window.twttr === "undefined") {
      return;
    }
    if (typeof window.twttr.widgets !== "undefined") {
      window.twttr.widgets.load();
    }
  }, []);
  const text = "Learn TypeScript: " + title + " via @carlrippon "; // + url;
  const encodedText = encodeURIComponent(text);
  return (
    <div
      css={{
        margin: "10px 0px 30px",
      }}
    >
      <h2 css={{ marginTop: `50px` }}>Did you find this lesson useful?</h2>
      <div
        css={{
          margin: "10px 0px 30px",
          display: `flex`,
          alignItems: `center`,
        }}
      >
        <div css={{ height: "26px", margin: "0 5px 10px 0" }}>
          Share this lesson on twitter
        </div>
        <a
          className="twitter-share-button"
          href={`https://twitter.com/intent/tweet?text=${encodedText}`}
          data-size="large"
        >
          Tweet
        </a>
      </div>
      <Helmet
        script={[
          {
            type: "text/javascript",
            innerHTML: `window.twttr = (function(d, s, id) {
              var js, fjs = d.getElementsByTagName(s)[0],
                t = window.twttr || {};
              if (d.getElementById(id)) return t;
              js = d.createElement(s);
              js.id = id;
              js.src = "https://platform.twitter.com/widgets.js";
              fjs.parentNode.insertBefore(js, fjs);
            
              t._e = [];
              t.ready = function(f) {
                t._e.push(f);
              };
            
              return t;
            }(document, "script", "twitter-wjs"));`,
          },
        ]}
      />
    </div>
  );
};
