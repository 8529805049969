import React from "react";
import ReactMarkdown from "react-markdown";
import { Code } from "./code";

export const Question = ({
  question,
  questionCode,
  answer,
  answerCode,
  language,
}) => {
  const [show, setShow] = React.useState(false);

  const handleClick = () => {
    setShow(!show);
  };
  return (
    <div css={{ marginBottom: "20px" }}>
      {question && (
        <div css={{ margin: "30px 0px 5px 0px" }}>
          <div css={{ display: "flex" }}>
            <span css={{ marginRight: "8px" }} role="img" aria-label="question">
              🤔
            </span>
            <ReactMarkdown source={`${question}`} />
          </div>
          {questionCode && (
            <Code codeString={questionCode} language={language}></Code>
          )}
        </div>
      )}
      <div
        css={
          show
            ? { padding: "5px 15px 10px 15px", border: "1px solid #d8d8d8" }
            : {
                padding: "5px 15px 10px 15px",
                borderRadius: "4px",
                border: "1px solid transparent",
              }
        }
      >
        <button
          onClick={handleClick}
          css={{
            margin: "8px auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px 12px",
            minWidth: "180px",
            fontWeight: "600",
            fontSize: "0.8rem",
            lineHeight: "24px",
            whiteSpace: "nowrap",
            borderRadius: "4px",
            border: "1px solid #d8d8d8",
            letterSpacing: "0.35px",
            backgroundColor: "#fbfbfb",
            cursor: "pointer",
            ":hover": {
              backgroundColor: "#f7f7f7",
            },
            ":focus": {
              outline: "none",
            },
          }}
        >
          <Bulb />
          <span className="question-button-text">
            {show ? "Hide Answer" : "Show Answer"}
          </span>
        </button>
        {show && (
          <div
            css={{
              margin: "0",
              animation: "fadeIn ease 1s",
              WebkitAnimation: "fadeIn ease 1s",
              MozAnimation: "fadeIn ease 1s",
            }}
          >
            <ReactMarkdown source={answer} />
            {answerCode && (
              <Code codeString={answerCode} language={language}></Code>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const Bulb = () => (
  <span css={{ marginRight: "5px" }} role="img" aria-label="Bulb">
    💡
  </span>
);
