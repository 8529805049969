export default {
  colors: {
    primary: "#3b3b5d",
    background: "#FFFFFF",
    shape: `#F2F2FA`,
    title: `#3D3D4D`,
    text: `#42424a`,
    link: `#1ea69a`,
  },
};
